<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51"><defs/><g id="create-folder_svg__Groupe_986" transform="translate(-1697 -210)"><g id="create-folder_svg__Groupe_654" transform="translate(906.154 -1066.26)"><path id="create-folder_svg__Tracé_541" d="M831.516 1294.249a1.5 1.5 0 00-1.267-.615h-16.725a2.662 2.662 0 00-2.324 1.618l-5.61 12.857-.011.027a2.636 2.636 0 01-2.034 1.567 1.319 1.319 0 01-1.391-1.317v-18.7a.676.676 0 01.675-.676h4.906l1.751 1.752a.612.612 0 00.433.179h9.681v1.319a.612.612 0 101.223 0v-1.93a.612.612 0 00-.612-.612h-10.039l-1.751-1.752a.611.611 0 00-.433-.179h-5.159a1.9 1.9 0 00-1.9 1.9v18.7a2.552 2.552 0 00.793 1.844 2.518 2.518 0 001.728.7h9.441a.612.612 0 100-1.223H806a3.552 3.552 0 00.716-1.121l5.61-12.857.011-.027a1.423 1.423 0 011.187-.845h16.725a.323.323 0 01.26.085.369.369 0 01-.012.314l-2.066 4.816a.612.612 0 001.124.482l2.071-4.829.009-.024a1.554 1.554 0 00-.119-1.453z"/><path id="create-folder_svg__Tracé_542" d="M960.755 1419.76a8.215 8.215 0 105.941 2.553 8.213 8.213 0 00-5.941-2.553zm0 15.184a6.978 6.978 0 116.98-6.98 6.991 6.991 0 01-6.98 6.98z" transform="translate(-138.37 -120.438)"/><path id="create-folder_svg__Tracé_543" d="M1009.319 1472.654h-2.642v-2.643a.612.612 0 10-1.223 0v2.643h-2.643a.612.612 0 100 1.223h2.643v2.642a.612.612 0 101.223 0v-2.642h2.643a.612.612 0 000-1.223z" transform="translate(-183.682 -165.74)"/></g></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconCreateFolder'
  };
</script>
