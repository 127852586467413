<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.254 36.996"><path d="M10.997 4.12H8.269a1.237 1.237 0 00-1.235 1.235v9.332a1.238 1.238 0 001.235 1.236h2.733a1.238 1.238 0 001.236-1.236V5.36a1.237 1.237 0 00-1.241-1.24zm-.363 10.2H8.627v-8.6h2.007zM20.783 4.12h-2.732a1.237 1.237 0 00-1.236 1.235v9.332a1.238 1.238 0 001.236 1.236h2.732a1.238 1.238 0 001.236-1.236V5.36a1.237 1.237 0 00-1.236-1.24zm-.363 10.2h-2.007v-8.6h2.007z"/><path d="M43.46 35.396h-3.941l.847-.179a1.665 1.665 0 001.283-1.97L34.908 1.319a1.663 1.663 0 00-1.97-1.283l-7.885 1.665a1.639 1.639 0 00-.23.065 1.662 1.662 0 00-1.374-.727h-8.06a1.66 1.66 0 01-1.725 0h-8.06a1.664 1.664 0 00-1.662 1.663v32.694H.801a.8.8 0 100 1.6H43.46a.8.8 0 000-1.6zm-29.731-.063a.063.063 0 01-.063.063h-8.06a.063.063 0 01-.063-.063v-2.37h8.186zm0-3.967H5.543v-.918h8.186zm0-2.517H5.543V2.702a.063.063 0 01.063-.063h8.06a.063.063 0 01.063.063zm9.784 6.484a.063.063 0 01-.063.063h-8.06a.063.063 0 01-.063-.063v-2.37h8.185zm0-3.967h-8.185v-.918h8.185zm0-2.517h-8.185V2.702a.063.063 0 01.063-.063h8.06a.063.063 0 01.063.063zm1.831-25.556a.074.074 0 01.04-.027l7.886-1.664h.014a.053.053 0 01.033.011.058.058 0 01.028.039l5.363 25.4-8.009 1.69-5.362-25.4a.065.065 0 01.002-.049zm-.233 32.1V10.024l4.147 19.641a.734.734 0 00.016.195l.52 2.463a.8.8 0 00.064.186l.609 2.886zm5.917-5.089l7.986-1.686.189.9-7.985 1.685zm1.075 5a.066.066 0 01-.027-.039l-.527-2.5 8.008-1.691.528 2.5a.058.058 0 01-.009.047.061.061 0 01-.04.027l-7.885 1.665a.06.06 0 01-.053-.006z"/><path d="M33.403 15.151a1.232 1.232 0 00.78-.535 1.22 1.22 0 00.174-.93L32.43 4.551a1.237 1.237 0 00-1.466-.954l-2.674.565a1.236 1.236 0 00-.954 1.464l1.928 9.131a1.238 1.238 0 001.207.981 1.3 1.3 0 00.258-.027zm-4.426-9.5l1.962-.414 1.778 8.42-1.962.414z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconCloudDocument'
  };
</script>
