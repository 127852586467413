<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M34.362 21.61h-.707V18.4a6.411 6.411 0 00-6.4-6.4H24.23a6.411 6.411 0 00-6.4 6.4v3.21h-.71A2.123 2.123 0 0015 23.73v13.285a2.123 2.123 0 002.12 2.12h17.242a2.123 2.123 0 002.12-2.12V23.73a2.123 2.123 0 00-2.12-2.12zM18.957 18.4a5.279 5.279 0 015.273-5.269h3.021a5.279 5.279 0 015.273 5.269v3.21h-1.7V18.7a3.873 3.873 0 00-3.864-3.873h-2.438a3.873 3.873 0 00-3.869 3.873v2.91h-1.7l.004-3.21zm2.827 3.21V18.7a2.74 2.74 0 012.738-2.743h2.438A2.741 2.741 0 0129.7 18.7v2.91h-7.916zm-4.664 1.131h17.242a.99.99 0 01.989.989v11.447h-19.22V23.73a.99.99 0 01.989-.989zM29.367 38l-1.7-1.7h2.358l1.7 1.7h-2.358zm-3.957 0l-1.7-1.7h2.358l1.7 1.7H25.41zm-3.958 0l-1.7-1.7h2.363l1.7 1.7h-2.363zm-5.322-.989v-.707h2.028l1.7 1.7H17.12a.99.99 0 01-.989-.989l-.001-.004zM34.362 38h-1.038l-1.7-1.7h3.723v.707a.99.99 0 01-.989.989" fill="#fff"/><path d="M23.818 30.122l-.424 2.98a.587.587 0 00.579.667h3.508a.583.583 0 00.579-.661l-.395-2.983a2.923 2.923 0 10-3.847 0v-.003zm1.925-3.95a1.753 1.753 0 01.973 3.212.586.586 0 00-.254.562l.352 2.656h-2.17l.378-2.651a.586.586 0 00-.254-.568 1.753 1.753 0 01.973-3.212" fill="#fff"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconActionLock'
  };
</script>
