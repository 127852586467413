<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g data-name="Groupe 676" stroke="#000" stroke-width="2" fill="none"><g data-name="Ellipse 46"><circle cx="24" cy="24" r="24" stroke="none"/><circle cx="24" cy="24" r="23"/></g><path data-name="Tracé 561" d="M14.142 23.721l6.758 6.758 12.608-12.608" stroke-linecap="round" stroke-miterlimit="10"/></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconCheckBig'
  };
</script>
