<template>
  <modal class="text-prompt-modal" @close="$emit('close')">
    <!-- Titre -->
    <div class="modal-header">
      <div
        :class="isGC ? 'app-logo-minified' : 'app-logo'"
        v-html="isGC ? logoMinified : logo"
      />
    </div>

    <!-- Corps -->
    <form class="modal-body" @submit="onValidate">
      <div class="modal-body-title">
        <slot name="icon">
          <MeepIconExclamationMark class="app-icon" />
        </slot>
        {{ title }}
      </div>
      <div class="modal-body-content">
        <md-field :class="{ 'md-invalid': errors.has('text') }">
          <label>
            {{ label }}
          </label>

          <md-input
            v-model="text"
            v-validate="validation"
            name="text"
            type="text"
          >
          </md-input>

          <span v-show="errors.has('text')" class="md-error">
            {{ errors.first("text") }}
          </span>
        </md-field>
      </div>

      <div class="modal-footer">
        <md-button
          class="md-primary--inverted"
          type="button"
          @click.native="$emit('close')"
        >
          Annuler
        </md-button>

        <md-button
          class="md-raised md-primary"
          type="submit"
          @click="onValidate"
        >
          Valider
        </md-button>
      </div>
    </form>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import MeepIconExclamationMark from "@/components/icons/MeepIconExclamationMark.vue";

export default {
  name: "TextPromptModal",
  components: {
    MeepIconExclamationMark,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    validation: {
      type: String,
      required: false,
      default: "required",
    },
    object: {
      type: Object,
      required: false,
      default: () => {
      },
    },
    defaultText: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      text: this.defaultText,
    };
  },

  computed: {
    ...mapGetters(["logo", "logoMinified", "isGC"]),
  },

  methods: {
    onValidate() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit("validate", this.text);
          this.$emit("close");
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

.text-prompt-modal {
  .modal-container {
    width: 960px;
    height: 480px;
    border-radius: 27px;
    padding: 20px 38px;
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .app-logo {
      svg {
        width: 105px;
      }
    }
  }

  .modal {
    &-body {
      padding: 0 20px;

      p {
        margin: 5px 0;
      }

      &-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: var(--modal-title-size-lg);
        font-family: var(--font-bold);
        margin-bottom: 15px;
        gap: 30px;
        @include for-lg{
          font-size: var(--modal-title-size-xl);
        }
        .app-icon {
          width: var(--modal-icon-size-lg);
          height: var(--modal-icon-size-lg);
          @include for-lg{
            width: var(--modal-icon-size-xl);
            height: var(--modal-icon-size-xl);
          }

          &-big {
            width: 75px;
            height: 75px;
          }
        }
      }

      &-content {
        display: flex;
        justify-content: center;

        .md-field {
          width: 670px;

          &.md-focused,
          &.md-has-value {
            label {
              display: none;
            }
          }
        }
      }
    }
  }

  .modal-footer {
    padding: 20px;
    display: flex;
    justify-content: center;

    .md-primary {
      width: 177.2px;
    }
  }

  .modal-list {
    max-height: 420px;
    overflow-y: scroll;
  }
}
</style>
