<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51"><g fill="none" stroke="#fff" stroke-width="1.3"><g transform="translate(12 12)"><circle cx="14" cy="14" r="14" stroke="none"/><circle cx="14" cy="14" r="13.35"/></g><path stroke-linecap="round" stroke-miterlimit="10" d="M20.392 25.737l3.913 3.914 7.3-7.3"/></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconManage'
  };
</script>
