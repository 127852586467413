<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.747 26.747"><defs/><g id="TopBarBoxClose_svg__Groupe_549" transform="rotate(45 1159.958 -2013.557)"><g id="TopBarBoxClose_svg__Ellipse_46" fill="none" stroke="#000" stroke-width=".8" transform="translate(1773 221)"><circle cx="9.457" cy="9.457" r="9.457" stroke="none"/><circle cx="9.457" cy="9.457" r="9.057"/></g><g id="TopBarBoxClose_svg__Groupe_548" transform="translate(1778.404 226.404)"><path id="TopBarBoxClose_svg__Ligne_94" d="M0 0v8.106" transform="translate(4.053)"/><path id="TopBarBoxClose_svg__Ligne_95" d="M0 0h8.106" transform="translate(0 4.053)"/></g></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconTopBarBoxClose'
  };
</script>
