<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51"><defs/><g id="cut_svg__Groupe_985" transform="translate(-1505 -210)"><g id="cut_svg__Groupe_674" transform="translate(-489.66 -1065.963)"><path id="cut_svg__Tracé_562" d="M2117.25 1310.483a2.91 2.91 0 10-2.91 2.911 2.914 2.914 0 002.91-2.911zm-4.657 0a1.746 1.746 0 111.747 1.747 1.748 1.748 0 01-1.747-1.747z" transform="translate(-94.18 -18.181)"/><path id="cut_svg__Tracé_563" d="M2199.262 1392.506a2.91 2.91 0 102.91 2.91 2.914 2.914 0 00-2.91-2.91zm0 4.656a1.746 1.746 0 111.746-1.746 1.748 1.748 0 01-1.746 1.746z" transform="translate(-170.178 -94.189)"/><path id="cut_svg__Tracé_564" d="M2029.083 1296.182a5.038 5.038 0 00-4.657 3.107 1.394 1.394 0 01-1.292.861h-1.9v-1.9a1.4 1.4 0 01.863-1.295 5.047 5.047 0 10-4.658-.407 1.4 1.4 0 01.648 1.182v1.538a1.411 1.411 0 01-.145.62 2.581 2.581 0 00-.841.382l-9.808 6.825a2.547 2.547 0 00-1.106 2.106v.947a.582.582 0 00.842.521l7.363-3.681-3.683 7.364a.582.582 0 00.521.843h.948a2.567 2.567 0 002.106-1.1l6.826-9.814a2.565 2.565 0 00.379-.84 1.362 1.362 0 01.618-.144h1.542a1.409 1.409 0 011.181.649 5.045 5.045 0 104.249-7.764zm-21.729 13.028v-.005a1.386 1.386 0 01.6-1.148l9.057-6.3-1.751 3.5zm5.978 4.222a1.4 1.4 0 01-1.15.6h-.007l6.809-13.614a2.58 2.58 0 00.271-1.147v-1.538a2.556 2.556 0 00-1.186-2.163 3.88 3.88 0 113.584.314 2.559 2.559 0 00-1.58 2.369v2.73a2.57 2.57 0 00.077.622l.218.871a1.4 1.4 0 01-.209 1.14zm15.752-8.324a3.864 3.864 0 01-3.267-1.787 2.582 2.582 0 00-2.162-1.188h-1.542a2.573 2.573 0 00-.613.075v-.012l-.218-.872v-.009h1.857a2.555 2.555 0 002.366-1.577 3.881 3.881 0 113.583 5.371z"/><path id="cut_svg__Tracé_565" d="M2123.348 1427.276a.743.743 0 10.745.744.744.744 0 00-.745-.744z" transform="translate(-104.181 -125.305)"/></g></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconCut'
  };
</script>
