<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33.06 18.4a.568.568 0 00.565-.623A6.406 6.406 0 0027.25 12h-3.019a6.405 6.405 0 00-6.4 6.4v3.21h-.711A2.119 2.119 0 0015 23.73v13.285a2.12 2.12 0 002.12 2.12h17.242a2.12 2.12 0 002.12-2.12V23.73a2.119 2.119 0 00-2.12-2.12H21.784v-2.917a2.736 2.736 0 012.736-2.736h2.442a2.739 2.739 0 012.7 2.266.566.566 0 001.118-.174 3.874 3.874 0 00-3.813-3.223H24.52a3.866 3.866 0 00-3.867 3.867v2.917h-1.7V18.4a5.274 5.274 0 015.278-5.269h3.019a5.275 5.275 0 015.25 4.777.557.557 0 00.56.492zM17.12 38a.99.99 0 01-.989-.989v-.707h2.029l1.7 1.7L17.12 38zm4.33 0l-1.7-1.7h2.367l1.7 1.7H21.45zm3.957 0l-1.7-1.7h2.363l1.7 1.7h-2.363zm3.957 0l-1.7-1.7h2.363l1.7 1.7h-2.363zm5.987-.989a.99.99 0 01-.989.989h-1.04l-1.7-1.7h3.725l.004.711zm0-1.837h-19.22V23.73a.994.994 0 01.989-.989h17.242a.994.994 0 01.989.989v11.444z" fill="#fff"/><path d="M23.879 29.955l-.41 2.882a.568.568 0 00.56.645h3.392a.565.565 0 00.56-.639l-.382-2.885a2.827 2.827 0 10-3.72 0v-.003zm1.862-3.819a1.7 1.7 0 01.941 3.106.566.566 0 00-.246.544l.34 2.569h-2.1l.365-2.563a.566.566 0 00-.245-.549 1.7 1.7 0 01.941-3.106" fill="#fff"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconActionUnlock'
  };
</script>
