<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.246 15.468"><path data-name="Tracé 863" d="M11.037 5.478h-.4V3.651A3.654 3.654 0 006.983 0H5.262a3.654 3.654 0 00-3.651 3.651v1.827h-.4A1.209 1.209 0 000 6.687v7.573a1.209 1.209 0 001.208 1.208h9.829a1.209 1.209 0 001.208-1.208V6.687a1.209 1.209 0 00-1.208-1.208m-3.693 6.628l.055.419a.323.323 0 01-.077.255.316.316 0 01-.242.11H5.146a.323.323 0 01-.319-.367l.058-.412.177-1.231a.913.913 0 01-.081-.077 1.6 1.6 0 01-.47-1.134 1.833 1.833 0 01.032-.329 1.613 1.613 0 013.158 0 1.833 1.833 0 01.032.329 1.593 1.593 0 01-.477 1.141.537.537 0 01-.074.074zm1.035-6.629H3.867V3.816a1.561 1.561 0 011.56-1.56h1.392a1.561 1.561 0 011.56 1.56z" fill="#8adbfc"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconLockPath'
  };
</script>
