<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51"><defs/><g id="action_paste_svg__Paste" transform="translate(-1505 -210)"><g id="action_paste_svg__Groupe_1048" transform="translate(1517.723 222.478)"><path id="action_paste_svg__Tracé_821" d="M1066.256 3914.752h-18.94a.511.511 0 01-.511-.511V3895.3a.511.511 0 01.511-.511h18.94a.511.511 0 01.511.511v18.94a.511.511 0 01-.511.512zm-16.892-2.048h14.845a.511.511 0 00.511-.511v-14.845a.511.511 0 00-.511-.511h-14.845a.511.511 0 00-.511.511v14.845a.511.511 0 00.511.507z" transform="translate(-1046.806 -3894.791)"/><rect id="action_paste_svg__Rectangle_689" width="19.961" height="19.961" rx="1.33" transform="translate(5.593 5.561)"/></g></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconActionPaste'
  };
</script>
