<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.196 37.195"><path d="M37.192 22.396a.727.727 0 00-.012-.078c-.005-.025-.012-.05-.02-.075s-.009-.029-.015-.043l-.01-.028-6.963-18.568a.773.773 0 00-.725-.5h-5.424a.776.776 0 00-.776.8.8.8 0 00.811.753h4.18a.97.97 0 01.908.629l5.835 15.558a.637.637 0 01-.6.86h-5.712a.775.775 0 00-.694.427l-2.885 5.77H12.098l-2.884-5.77a.775.775 0 00-.694-.427H2.808a.637.637 0 01-.6-.86L8.043 5.286a.97.97 0 01.907-.629h4.216a.774.774 0 00.774-.8.8.8 0 00-.81-.753H7.742a.773.773 0 00-.725.5L.054 22.172l-.011.028-.014.043c-.004.014-.015.05-.02.075s-.009.052-.012.078a.72.72 0 000 .075v13.945a.778.778 0 00.778.778h35.639a.779.779 0 00.778-.778V22.471a.688.688 0 000-.075zm-1.546 12.261a.988.988 0 01-.988.988H2.54a.987.987 0 01-.988-.988V24.233a.987.987 0 01.988-.987h4.9a.987.987 0 01.884.546l2.613 5.225a.773.773 0 00.692.427h13.949a.773.773 0 00.693-.427l2.613-5.225a.985.985 0 01.882-.546h4.9a.988.988 0 01.988.987z"/><path d="M26.097 16.069l-6.95 6.95a.492.492 0 01-.055.05.462.462 0 01-.059.045.742.742 0 01-.132.071.664.664 0 01-.145.044.738.738 0 01-.077.012.633.633 0 01-.1 0 .751.751 0 01-.079-.007.653.653 0 01-.075-.013.766.766 0 01-.373-.206l-6.951-6.951a.8.8 0 01-.124-1 .776.776 0 011.2-.124l5.34 5.342a.182.182 0 00.31-.129V.81a.8.8 0 01.755-.81.774.774 0 01.8.774v19.387a.182.182 0 00.31.128l5.341-5.341a.775.775 0 011.2.124.8.8 0 01-.136.997zM18.578 23.246a.62.62 0 00.1 0 .571.571 0 01-.08.005z"/><path d="M18.9 23.185a.721.721 0 00.132-.071.659.659 0 01-.132.071zM19.146 23.019a.457.457 0 01-.055.049.478.478 0 00.055-.049z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconCloudDepot'
  };
</script>
