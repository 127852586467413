<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27.23 14.464l3.409-2.218a.684.684 0 00-.746-1.146l-4.671 3.04a.684.684 0 00-.2.945l3.023 4.647a.684.684 0 101.146-.746l-2.053-3.156a10.879 10.879 0 11-5.506.995.681.681 0 00.35-.92.682.682 0 00-.932-.317 12.256 12.256 0 106.179-1.122v-.002z" fill="#000"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconActionRestore'
  };
</script>
